import { FC } from 'react';

const Footer: FC = () => {
  return (
    <footer className="bg-gray-900 text-white py-4 sm:py-6">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="text-center sm:text-left">
            <p className="text-sm sm:text-base">
              &copy; {new Date().getFullYear()} Perfect Secret. Tous droits réservés.
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-6 text-sm">
            <a 
              href="#" 
              className="text-white/80 hover:text-accent transition-colors"
            >
              Mentions légales
            </a>
            <a 
              href="#" 
              className="text-white/80 hover:text-accent transition-colors"
            >
              Politique de confidentialité
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;