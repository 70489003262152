import { FC } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, Users, Star } from 'lucide-react';

interface BenefitCardProps {
  icon: typeof TrendingUp;
  title: string;
  description: string;
  delay?: number;
}

const BenefitCard: FC<BenefitCardProps> = ({ icon: Icon, title, description, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay }}
      className="w-full"
    >
      <div className="bg-white rounded-xl p-6 sm:p-8 shadow-lg hover:shadow-xl transition-all duration-300 h-full">
        <div className="flex flex-col items-center justify-center text-center gap-4 sm:gap-6">
          <div className="relative">
            <div className="absolute inset-0 bg-accent/20 rounded-full blur-xl group-hover:blur-2xl transition-all" />
            <div className="relative bg-white rounded-full p-3 sm:p-4 shadow-md">
              <Icon className="w-6 h-6 sm:w-8 sm:h-8 text-accent" />
            </div>
          </div>

          <div className="space-y-3">
            <motion.h3 
              className="text-xl sm:text-2xl font-semibold tracking-tight"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              {title}
            </motion.h3>
            
            <p className="text-sm sm:text-base text-gray-600 leading-relaxed">
              {description}
            </p>
          </div>
        </div>

        <div className="absolute inset-0 border-2 border-accent/0 rounded-xl group-hover:border-accent/10 transition-all duration-300" />
      </div>
    </motion.div>
  );
};

const Benefits: FC = () => {
  const benefits = [
    {
      icon: TrendingUp,
      title: "Augmentation du CA",
      description: "Augmentez immédiatement votre chiffre d'affaires avec une prestation haut de gamme qui peut représenter jusqu'à 4000€ de revenus supplémentaires par mois."
    },
    {
      icon: Users,
      title: "Fidélisation Cliente",
      description: "Garantissez des retours réguliers pour l'entretien et les retouches. Vos clientes deviennent des ambassadrices naturelles de votre expertise."
    },
    {
      icon: Star,
      title: "Expertise Unique",
      description: "Démarquez-vous de la concurrence en devenant la référence locale incontestable en matière d'extensions capillaires de qualité."
    }
  ];

  return (
    <section id="benefits" className="relative py-12 sm:py-16 lg:py-24 bg-gray-50 overflow-hidden">
      <div className="absolute top-0 left-0 w-1/2 h-72 bg-accent/5 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 right-0 w-1/2 h-96 bg-accent/5 rounded-full blur-3xl translate-x-1/3 translate-y-1/3" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-12 sm:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6">
            Ce que cette formation peut apporter à 
            <span className="text-accent"> votre salon</span>
          </h2>
          <p className="text-base sm:text-lg text-gray-600">
            Transformez votre salon et votre expertise avec notre formation spécialisée
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 max-w-6xl mx-auto">
          {benefits.map((benefit, index) => (
            <BenefitCard
              key={index}
              {...benefit}
              delay={index * 0.2}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="mt-12 sm:mt-16 text-center"
        >
          <button 
            onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
            className="inline-flex items-center justify-center px-6 py-3 sm:px-8 sm:py-4 
                     bg-accent hover:bg-accent/90 text-white font-bold rounded-lg 
                     text-base sm:text-lg transition-all transform hover:scale-105"
          >
            Découvrir le Programme
          </button>
        </motion.div>
      </div>
    </section>
  );
};

export default Benefits;