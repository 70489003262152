import { FC } from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, Calendar, Users, TrendingUp } from 'lucide-react';
import formateurVideo from '../assets/formateur_action.mp4';

interface FeatureProps {
  icon: typeof CheckCircle;
  text: string;
}

const Feature: FC<FeatureProps> = ({ icon: Icon, text }) => (
  <motion.div 
    initial={{ opacity: 0, x: -20 }}
    whileInView={{ opacity: 1, x: 0 }}
    viewport={{ once: true }}
    className="flex items-center space-x-3 text-gray-700"
  >
    <Icon className="w-5 h-5 text-accent flex-shrink-0" />
    <span>{text}</span>
  </motion.div>
);

const StatBadge: FC<{ icon: typeof Calendar; label: string; value: string }> = ({ 
  icon: Icon, 
  label, 
  value 
}) => (
  <div className="flex items-center gap-3 bg-white/50 backdrop-blur-sm rounded-lg p-3">
    <div className="bg-accent/10 rounded-lg p-2">
      <Icon className="w-5 h-5 text-accent" />
    </div>
    <div className="text-left">
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-semibold">{value}</p>
    </div>
  </div>
);

const Trainer: FC = () => {
  const features = [
    { icon: CheckCircle, text: "Méthode simple et efficace, centrée sur la pratique" },
    { icon: CheckCircle, text: "Accompagnement personnalisé post-formation" },
    { icon: CheckCircle, text: "Techniques exclusives et innovantes" },
    { icon: CheckCircle, text: "Rentabilisation en 1 à 2 mois" }
  ];

  const stats = [
    { icon: Calendar, label: "Durée", value: "2 jours intensifs" },
    { icon: Users, label: "Groupe", value: "8 pers. max" },
    { icon: TrendingUp, label: "Rentabilité", value: "1-2 mois" }
  ];

  return (
    <section id="trainer" className="relative py-24 bg-white overflow-hidden">
      <div className="absolute top-0 right-0 w-1/3 h-full bg-accent/5 -skew-x-12 transform origin-top-right" />

      <div className="container mx-auto px-4 relative">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div 
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <div className="space-y-4">
                <motion.h2 
                  className="text-4xl font-bold"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                >
                  Pourquoi ma formation est{' '}
                  <span className="text-accent">LA solution idéale</span>
                </motion.h2>

                <p className="text-lg text-gray-600 leading-relaxed">
                  Expert reconnu avec plusieurs années d'expérience dans la pose d'extensions, 
                  je forme des professionnels à travers toute la France.
                </p>
              </div>

              <div className="grid gap-4">
                {features.map((feature, index) => (
                  <Feature key={index} {...feature} />
                ))}
              </div>

              <div className="grid grid-cols-3 gap-4">
                {stats.map((stat, index) => (
                  <StatBadge key={index} {...stat} />
                ))}
              </div>

              <motion.div 
                className="bg-accent/5 p-6 rounded-xl border border-accent/10"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <p className="text-sm text-gray-700 italic">
                  Première session exclusive en petit comité - Places très limitées
                </p>
              </motion.div>
            </motion.div>

            {/* Vidéo du formateur */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              className="relative aspect-[4/5] rounded-2xl overflow-hidden shadow-2xl"
            >
              <div className="absolute inset-0 bg-gradient-to-tr from-accent/20 to-transparent" />
              <video
                className="w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={formateurVideo} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéos.
              </video>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trainer;