import Header from './components/Header';
import MarketContext from './components/MarketContext';
import Benefits from './components/Benefits';
import Trainer from './components/Trainer';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="min-h-screen bg-white overflow-x-hidden">
      <Navbar />
      <main className="flex flex-col w-full">
        <Header />
        <div className="space-y-0">
          <MarketContext />
          <Benefits />
          <Trainer />
          <ContactForm />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;