import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Send, User, Mail, Phone, Award, Home, MapPin } from 'lucide-react';

interface FormInputs {
  nom: string;
  prenom: string;
  email: string;
  telephone: string;
  nomSalon: string;
  adresseSalon: string;
  villeSalon: string;
  codePostalSalon: string;
  statutProfessionnel: string;
  experience: string;
  motivations: string;
}

const ContactForm: FC = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormInputs>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const statutsCoiffure = [
    { value: "Salarié", label: "Salarié(e)" },
    { value: "Indépendant", label: "Indépendant(e)" },
    { value: "Gérant", label: "Gérant(e)" }
  ];

  const onSubmit = async (data: FormInputs) => {
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      const response = await fetch('https://n8n.keepzy.info/webhook/1b15f7f8-ec39-4ffc-ad01-c5aae4e15fb0', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: new Date().toISOString(),
          ...data
        })
      });

      if (response.ok) {
        setSubmitStatus('success');
        reset();
      } else {
        throw new Error('Échec de la soumission');
      }
    } catch (error: unknown) {
      console.error('Erreur lors de l\'envoi du formulaire:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClasses = `
    w-full px-4 py-3 rounded-lg border bg-white/50 backdrop-blur-sm 
    transition-all focus:ring-2 focus:ring-accent focus:border-accent 
    outline-none text-sm sm:text-base disabled:opacity-50 disabled:cursor-not-allowed
  `;
  
  const labelClasses = "block text-sm font-medium text-gray-700 mb-1";
  const errorClasses = "text-red-500 text-xs sm:text-sm mt-1";
  const sectionClasses = "space-y-6";

  return (
    <section id="contact" className="relative py-12 sm:py-16 lg:py-24 bg-gray-50 overflow-hidden">
      <div className="absolute top-0 left-0 w-72 h-72 bg-accent/5 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-accent/5 rounded-full blur-3xl translate-x-1/3 translate-y-1/3" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto"
        >
          <div className="text-center mb-12">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">
              Intéressé(e) ? <span className="text-accent">Candidatez</span> maintenant
            </h2>
            <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
              Cette formation s'adresse aux coiffeurs soucieux de faire évoluer leur offre. 
              Nous sélectionnons soigneusement nos candidats pour maintenir un niveau d'excellence.
            </p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-12 relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-tr from-accent/5 to-transparent" />

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 relative">
              {/* Informations personnelles */}
              <div className={sectionClasses}>
                <h3 className="text-xl font-semibold mb-4">Informations personnelles</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <User className="w-4 h-4 text-accent" />
                        <span>Nom</span>
                      </div>
                    </label>
                    <input
                      {...register("nom", { required: "Le nom est requis" })}
                      className={`${inputClasses} ${errors.nom ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="Votre nom de famille"
                    />
                    {errors.nom && <p className={errorClasses}>{errors.nom.message}</p>}
                  </div>

                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <User className="w-4 h-4 text-accent" />
                        <span>Prénom</span>
                      </div>
                    </label>
                    <input
                      {...register("prenom", { required: "Le prénom est requis" })}
                      className={`${inputClasses} ${errors.prenom ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="Votre prénom"
                    />
                    {errors.prenom && <p className={errorClasses}>{errors.prenom.message}</p>}
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <Mail className="w-4 h-4 text-accent" />
                        <span>Email</span>
                      </div>
                    </label>
                    <input
                      type="email"
                      {...register("email", {
                        required: "L'email est requis",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Adresse email invalide"
                        }
                      })}
                      className={`${inputClasses} ${errors.email ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="votre@email.com"
                    />
                    {errors.email && <p className={errorClasses}>{errors.email.message}</p>}
                  </div>

                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <Phone className="w-4 h-4 text-accent" />
                        <span>Téléphone</span>
                      </div>
                    </label>
                    <input
                      {...register("telephone", {
                        required: "Le téléphone est requis",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Le numéro de téléphone doit contenir 10 chiffres"
                        }
                      })}
                      className={`${inputClasses} ${errors.telephone ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="0612345678"
                    />
                    {errors.telephone && <p className={errorClasses}>{errors.telephone.message}</p>}
                  </div>
                </div>
              </div>

              {/* Informations du salon */}
              <div className={sectionClasses}>
                <h3 className="text-xl font-semibold mb-4">Informations du salon</h3>
                <div className="grid gap-6">
                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <Home className="w-4 h-4 text-accent" />
                        <span>Nom du salon</span>
                      </div>
                    </label>
                    <input
                      {...register("nomSalon", { required: "Le nom du salon est requis" })}
                      className={`${inputClasses} ${errors.nomSalon ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="Nom de votre salon"
                    />
                    {errors.nomSalon && <p className={errorClasses}>{errors.nomSalon.message}</p>}
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label className={labelClasses}>
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4 text-accent" />
                          <span>Adresse</span>
                        </div>
                      </label>
                      <input
                        {...register("adresseSalon", { required: "L'adresse du salon est requise" })}
                        className={`${inputClasses} ${errors.adresseSalon ? 'border-red-500' : 'border-gray-200'}`}
                        placeholder="Adresse du salon"
                      />
                      {errors.adresseSalon && <p className={errorClasses}>{errors.adresseSalon.message}</p>}
                    </div>

                    <div>
                      <label className={labelClasses}>
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4 text-accent" />
                          <span>Ville</span>
                        </div>
                      </label>
                      <input
                        {...register("villeSalon", { required: "La ville est requise" })}
                        className={`${inputClasses} ${errors.villeSalon ? 'border-red-500' : 'border-gray-200'}`}
                        placeholder="Ville du salon"
                      />
                      {errors.villeSalon && <p className={errorClasses}>{errors.villeSalon.message}</p>}
                    </div>

                    <div>
                      <label className={labelClasses}>
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4 text-accent" />
                          <span>Code Postal</span>
                        </div>
                      </label>
                      <input
                        {...register("codePostalSalon", {
                          required: "Le code postal est requis",
                          pattern: {
                            value: /^[0-9]{5}$/,
                            message: "Le code postal doit contenir 5 chiffres"
                          }
                        })}
                        className={`${inputClasses} ${errors.codePostalSalon ? 'border-red-500' : 'border-gray-200'}`}
                        placeholder="12345"
                      />
                      {errors.codePostalSalon && <p className={errorClasses}>{errors.codePostalSalon.message}</p>}
                    </div>
                  </div>
                </div>
              </div>

              {/* Profil professionnel */}
              <div className={sectionClasses}>
                <h3 className="text-xl font-semibold mb-4">Votre profil</h3>
                <div className="space-y-6">
                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <Award className="w-4 h-4 text-accent" />
                        <span>Statut professionnel</span>
                      </div>
                    </label>
                    <select
                      {...register("statutProfessionnel", { required: "Le statut professionnel est requis" })}
                      className={`${inputClasses} ${errors.statutProfessionnel ? 'border-red-500' : 'border-gray-200'}`}
                    >
                      <option value="">Sélectionnez votre statut</option>
                      {statutsCoiffure.map((statut) => (
                        <option key={statut.value} value={statut.value}>
                          {statut.label}
                        </option>
                      ))}
                    </select>
                    {errors.statutProfessionnel && (
                      <p className={errorClasses}>{errors.statutProfessionnel.message}</p>
                    )}
                  </div>

                  <div>
                    <label className={labelClasses}>
                      <div className="flex items-center gap-2">
                        <Award className="w-4 h-4 text-accent" />
                        <span>Expérience en coiffure</span>
                      </div>
                    </label>
                    <input
                      {...register("experience", { required: "L'expérience est requise" })}
                      className={`${inputClasses} ${errors.experience ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="Nombre d'années d'expérience, spécialités..."
                    />
                    {errors.experience && <p className={errorClasses}>{errors.experience.message}</p>}
                  </div>

                  <div>
                    <label className={labelClasses}>Vos motivations</label>
                    <textarea
                      {...register("motivations", { required: "Les motivations sont requises" })}
                      rows={4}
                      className={`${inputClasses} resize-none ${errors.motivations ? 'border-red-500' : 'border-gray-200'}`}
                      placeholder="Pourquoi souhaitez-vous suivre cette formation ?"
                    />
                    {errors.motivations && <p className={errorClasses}>{errors.motivations.message}</p>}
                  </div>
                </div>
              </div>

              <motion.div
                className="text-center pt-4"
                whileHover={{ scale: isSubmitting ? 1 : 1.02 }}
               whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
             >
               <button
                 type="submit"
                 disabled={isSubmitting}
                 className={`
                   inline-flex items-center justify-center gap-2
                   w-full sm:w-auto px-6 py-3 sm:px-8 sm:py-4
                   bg-accent hover:bg-accent/90 text-white font-bold 
                   rounded-lg text-base sm:text-lg transition-all
                   disabled:opacity-50 disabled:cursor-not-allowed
                 `}
               >
                 {isSubmitting ? (
                   <>
                     <span>Envoi en cours...</span>
                     <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                   </>
                 ) : (
                   <>
                     <span>Soumettre ma Candidature</span>
                     <Send className="w-5 h-5" />
                   </>
                 )}
               </button>

               {submitStatus === 'success' && (
                 <motion.p
                   initial={{ opacity: 0 }}
                   animate={{ opacity: 1 }}
                   className="text-green-600 mt-4"
                 >
                   Votre candidature a été envoyée avec succès !
                 </motion.p>
               )}

               {submitStatus === 'error' && (
                 <motion.p
                   initial={{ opacity: 0 }}
                   animate={{ opacity: 1 }}
                   className="text-red-600 mt-4"
                 >
                   Une erreur est survenue lors de l'envoi. 
                   Veuillez réessayer ou nous contacter directement.
                 </motion.p>
               )}
             </motion.div>
           </form>
         </motion.div>
       </motion.div>
     </div>
   </section>
 );
};

export default ContactForm;