import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import heroImage from '../assets/hero-image.jpg';

const CountdownTimer = () => {
  const targetDate = new Date('2025-02-02T00:00:00');
  const [timeLeft, setTimeLeft] = useState({
    jours: 0,
    heures: 0,
    minutes: 0,
    secondes: 0
  });

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();
    
    if (difference <= 0) {
      return {
        jours: 0,
        heures: 0,
        minutes: 0,
        secondes: 0
      };
    }

    return {
      jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
      heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      secondes: Math.floor((difference / 1000) % 60)
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatNumber = (num: number): string => {
    return num < 10 ? `0${num}` : num.toString();
  };

  const getLabel = (value: number, unit: string): string => {
    const labels: { [key: string]: [string, string] } = {
      jours: ['Jour', 'Jours'],
      heures: ['Heure', 'Heures'],
      minutes: ['Minute', 'Minutes'],
      secondes: ['Seconde', 'Secondes']
    };
    return value <= 1 ? labels[unit][0] : labels[unit][1];
  };

  if (timeLeft.jours === 0 && timeLeft.heures === 0 && 
      timeLeft.minutes === 0 && timeLeft.secondes === 0) {
    return (
      <div className="text-white text-base sm:text-xl">
        La session a commencé !
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="text-center">
          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-2 sm:p-4 min-w-[60px] sm:min-w-[80px]">
            <span className="text-xl sm:text-3xl font-bold text-white">{formatNumber(value)}</span>
          </div>
          <span className="text-xs sm:text-sm text-white/80 mt-1 sm:mt-2">{getLabel(value, unit)}</span>
        </div>
      ))}
    </div>
  );
};

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="relative min-h-[100svh] flex items-center justify-center">
      <div className={`absolute inset-0 ${isScrolled ? 'bg-[#0F322B]' : ''}`}>
        <img
          src={heroImage}
          alt="Extensions capillaires professionnelles"
          className="w-full h-full object-cover object-center"
          loading="eager"
        />
        <div className="absolute inset-0 bg-black/50" />
      </div>

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto text-center"
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-bold text-white mb-4 sm:mb-6">
            De Coiffeur à Expert
            <span className="text-accent block mt-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
              Devenez LA référence en extensions capillaires
            </span>
          </h1>

          <p className="text-base sm:text-lg md:text-xl text-white/90 mb-8 leading-relaxed max-w-3xl mx-auto">
            Rejoignez une formation intensive de 2 jours en présentiel pour offrir 
            à vos clientes un service haut de gamme et décupler vos revenus.
          </p>

          <div className="space-y-8">
            <div className="flex flex-col items-center">
              <CountdownTimer />
            </div>

            <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
              <button 
                onClick={scrollToContact}
                className="w-full sm:w-auto bg-accent hover:bg-accent/90 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-lg text-base sm:text-lg transition-all transform hover:scale-105"
              >
                Réserver Ma Place
              </button>
              
              <button 
                onClick={scrollToContact}
                className="w-full sm:w-auto bg-white/10 backdrop-blur-sm hover:bg-white/20 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-lg text-base sm:text-lg transition-all"
              >
                En Savoir Plus
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </header>
  );
}