import { FC } from 'react';
import { motion } from 'framer-motion';
import { Euro, TrendingUp, Users, LucideIcon } from 'lucide-react';

interface AnimatedNumberProps {
  value: string;
  duration?: number;
  prefix?: string;
  suffix?: string;
}

const AnimatedNumber: FC<AnimatedNumberProps> = ({ value, prefix = '', suffix = '' }) => {
  return (
    <motion.span
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="text-3xl sm:text-4xl lg:text-5xl font-bold text-accent"
    >
      {prefix}{value}{suffix}
    </motion.span>
  );
};

interface StatCardProps {
  icon: LucideIcon;
  title: string;
  value: {
    value: string;
    prefix?: string;
    suffix?: string;
    duration?: number;
  };
  description: string;
}

const StatCard: FC<StatCardProps> = ({ icon: Icon, title, value, description }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      <div className="bg-white rounded-xl p-6 sm:p-8 shadow-lg hover:shadow-xl transition-all duration-300 h-full">
        <div className="flex flex-col items-center justify-center text-center gap-4 sm:gap-6">
          <div className="relative">
            <div className="absolute inset-0 bg-accent/20 rounded-full blur-xl group-hover:blur-2xl transition-all" />
            <div className="relative bg-white rounded-full p-3 sm:p-4 shadow-md">
              <Icon className="w-6 h-6 sm:w-8 sm:h-8 text-accent" />
            </div>
          </div>
          
          <AnimatedNumber {...value} />
          
          <div className="space-y-3">
            <motion.h3 
              className="text-xl sm:text-2xl font-semibold tracking-tight text-gray-800"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              {title}
            </motion.h3>
            
            <p className="text-sm sm:text-base text-gray-600 leading-relaxed">
              {description}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const MarketContext: FC = () => {
  const stats: StatCardProps[] = [
    {
      icon: Euro,
      title: "Prix Moyen",
      value: { value: "800", prefix: "€", duration: 2 },
      description: "Pour une prestation d'extensions de qualité, vos clients sont prêts à investir significativement."
    },
    {
      icon: TrendingUp,
      title: "CA Mensuel Potentiel",
      value: { value: "4000", prefix: "+ ", suffix: "€" },
      description: "Avec seulement 5 clientes par mois, augmentez significativement votre chiffre d'affaires."
    },
    {
      icon: Users,
      title: "Satisfaction Client",
      value: { value: "100", suffix: "%" },
      description: "Un service haut de gamme et durable qui fidélise naturellement votre clientèle."
    }
  ];

  return (
    <section id="market" className="relative py-12 sm:py-16 lg:py-24 bg-gray-50 overflow-hidden">
      <div className="absolute top-0 left-0 w-1/2 h-72 bg-accent/5 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 right-0 w-1/2 h-96 bg-accent/5 rounded-full blur-3xl translate-x-1/3 translate-y-1/3" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-12 sm:mb-16"
        >
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6">
            Pourquoi vous devez vous lancer <span className="text-accent">MAINTENANT</span>
          </h2>
          
          <p className="text-base sm:text-lg text-gray-600">
            Le marché des extensions capillaires est en pleine croissance. 
            Positionnez-vous dès maintenant comme expert dans votre région.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 max-w-6xl mx-auto">
          {stats.map((stat, index) => (
            <StatCard 
              key={index} 
              {...stat} 
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="mt-12 sm:mt-16 text-center"
        >
          <button 
            onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
            className="inline-flex items-center justify-center px-6 py-3 sm:px-8 sm:py-4 
                     bg-accent hover:bg-accent/90 text-white font-bold rounded-lg 
                     text-base sm:text-lg transition-all transform hover:scale-105"
          >
            Je Saisis Cette Opportunité
          </button>
        </motion.div>
      </div>
    </section>
  );
};

export default MarketContext;